import { render, staticRenderFns } from "./_ManifestShipmentBody.vue?vue&type=template&id=d43f430c&scoped=true"
import script from "./_ManifestShipmentBody.vue?vue&type=script&lang=js"
export * from "./_ManifestShipmentBody.vue?vue&type=script&lang=js"
import style0 from "./_ManifestShipmentBody.vue?vue&type=style&index=0&id=d43f430c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d43f430c",
  null
  
)

export default component.exports